<template>
  <v-container>
    <v-row class="elevation-1">
      <v-col cols="6" lg="12">
        <CreateFiscalYear id="odUtilCreateFiscalYear"
          class="od-util-create-fiscal-year"
          :deptFiscalYear="selectedfiscalYear"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CreateFiscalYear from '../../../components/OD/CreateFiscalYear.vue';
import { destructureDate } from '../../../util/shared/tmc-global';

export default {
  name: 'OfficialDepositoryCreateFiscalYear',
  components: {
    CreateFiscalYear,
  },
  data: () => ({
    selectedfiscalYear: destructureDate().year,
  }),
};
</script>
